.navbar-toggle {
    display: none;
}

.navbar-brand {
    padding-left: 35px;
    width: ($w-left-menu-desktop - 1);

    @media (max-width: $screen-lg-max) {
        padding-left: 15px;
        width: ($w-left-menu-desktop-lg - 1);
    }

    svg {
        height: 100%;
        width: 100%;

        @media (max-width: $screen-lg-max) {
            height: 41px;
            width: 150px;
        }
    }

    img {
        height: 46px;
        width: 180px;

        @media (max-width: $screen-lg-max) {
            height: 41px;
            width: 150px;
        }
    }

    div {
        height: 46px;
        width: 180px;
        border-style: dashed;
        border-color: #9d9d9d;
        color: #9d9d9d;
        font-size:14px;
        font-weight:500;
        border-width: 1px;
        text-align: center;

        @media (max-width: $screen-lg-max) {
            height: 41px;
            width: 150px;
        }
    }

    .auth & {
        display: block;
        width: auto;
        @include padding(0 0 0 0);
        margin-left: -75px;
        margin-right: -75px;

        @media (max-height: 600px) {
            @include padding(null null 20px null);
        }

        svg {
            height: 100%;
            width: 100%;
        }
    }
}
